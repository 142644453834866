var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.roomInfo)?_c('div',{staticClass:"shuffled game-room container"},[_c('div',{staticClass:"full-page-background"}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.customOptions.style)}}),(_vm.roomInfo)?_c('div',{},[(
        !_vm.customOptions.hideTitleInSession &&
          (_vm.customOptions.gameTitle || _vm.customOptions.byline)
      )?_c('div',{staticClass:"mb-4 game-meta d-none d-sm-block"},[(_vm.customOptions.gameTitle)?_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-sm"},[_c('h1',[_vm._v(_vm._s(_vm.customOptions.gameTitle))])])]):_vm._e(),(_vm.customOptions.byline)?_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-sm"},[_c('h4',[_vm._v(_vm._s(_vm.customOptions.byline))])])]):_vm._e()]):_vm._e(),(
        _vm.dataReady &&
          _vm.firebaseReady &&
          _vm.roomInfo &&
          Object.keys(_vm.roomInfo.extensionData).length > 1
      )?_c('div',[_c('app-extensionManager',{attrs:{"extensionData":_vm.roomInfo.extensionData,"extensionList":_vm.tempExtensionData,"roomInfo":_vm.roomInfo},on:{"sync-extension":function($event){return _vm.syncExtension()}}})],1):_vm._e(),_c('div',{staticClass:"row mb-4"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"btn-group col-sm",attrs:{"role":"group","aria-label":"Card Controls"}},[_c('button',{staticClass:"btn btn-outline-dark",attrs:{"disabled":_vm.roomInfo.xCardIsActive || _vm.roomInfo.currentCardIndex == 0},on:{"click":function($event){return _vm.previousCard()}}},[_vm._v(" Previous Card ")]),_c('button',{staticClass:"btn btn-outline-primary",attrs:{"disabled":_vm.roomInfo.xCardIsActive ||
                _vm.roomInfo.currentCardIndex >= _vm.roomInfo.locationOfLastCard},on:{"click":function($event){return _vm.nextCard()}}},[_vm._v(" Next Card ")])])])],1),(
        _vm.customOptions.instructionsProgressBar &&
          _vm.roomInfo.currentCardIndex < _vm.firstNonInstruction &&
          _vm.roomInfo.currentCardIndex != 0
      )?_c('div',{staticClass:"row mb-4 game-meta"},[_c('div',{staticClass:"col-sm"},[_c('h2',[_vm._v("Instructions")]),_c('b-progress',{attrs:{"value":_vm.roomInfo.currentCardIndex,"max":_vm.firstNonInstruction - 1,"variant":"dark"}})],1)]):_vm._e(),(_vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]])?_c('div',{staticClass:"mb-4"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"card d-flex shadow img-fluid",class:{
            'bg-transparent':
              _vm.customOptions.coverImage && _vm.roomInfo.currentCardIndex == 0,
          }},[(_vm.customOptions.coverImage && _vm.roomInfo.currentCardIndex == 0)?_c('img',{staticClass:"card-img-top",staticStyle:{"width":"100%"},attrs:{"src":_vm.customOptions.coverImage}}):_vm._e(),(
              _vm.customOptions.cardBackgroundImage &&
                (!_vm.customOptions.coverImage ||
                  _vm.roomInfo.currentCardIndex != 0) &&
                !_vm.customOptions.cardBackgroundImageAlign
            )?_c('img',{staticClass:"card-img-top card-background",staticStyle:{"width":"100%"},attrs:{"src":_vm.customOptions.cardBackgroundImage}}):_vm._e(),(
              _vm.customOptions.cardBackgroundImageAlign == 'top' &&
                _vm.roomInfo.currentCardIndex != 0
            )?_c('b-card-img',{attrs:{"src":_vm.customOptions.cardBackgroundImage,"alt":"Card Background image","top":""}}):_vm._e(),((!_vm.dataReady || !_vm.firebaseReady) && !_vm.error)?_c('div',{staticClass:"card-body text-center"},[_c('h1',{staticClass:"m-5"},[_vm._v("Loading")]),_c('b-spinner',{staticClass:"m-5",staticStyle:{"width":"4rem","height":"4rem"},attrs:{"label":"Busy"}})],1):_vm._e(),(!_vm.customOptions.coverImage || _vm.roomInfo.currentCardIndex != 0)?_c('div',[(!_vm.roomInfo.xCardIsActive)?_c('div',{staticClass:"card-body justify-content-center d-flex align-items-center mt-4",class:{
                'card-body': !_vm.customOptions.cardBackgroundImage,
                'card-img-overlay':
                  _vm.customOptions.cardBackgroundImage &&
                  !_vm.customOptions.cardBackgroundImageAlign,
              },staticStyle:{"white-space":"pre-line"}},[_c('div',[(!_vm.customOptions.hideHeadersOnCards)?_c('h1',[_vm._v(" "+_vm._s(_vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]] .headerText)+" ")]):_vm._e(),_c('p',{staticClass:"mt-4 mb-4",domProps:{"innerHTML":_vm._s(
                    _vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]]
                      .bodyText
                  )}})])]):_vm._e()]):_vm._e(),(_vm.customOptions.lastCardReminderText && _vm.customOptions.lastCardReminderFrequency && _vm.roomInfo.currentCardIndex > _vm.firstNonInstruction && ((_vm.roomInfo.currentCardIndex - _vm.firstNonInstruction) % _vm.customOptions.lastCardReminderFrequency == _vm.customOptions.lastCardReminderFrequency - 1))?_c('b-alert',{staticClass:"mx-3",attrs:{"show":"","variant":"info"},domProps:{"innerHTML":_vm._s(_vm.customOptions.lastCardReminderText)}}):_vm._e(),(_vm.roomInfo.xCardIsActive)?_c('div',{staticClass:"card-body align-items-center justify-content-center",class:{
              'card-body': !_vm.customOptions.cardBackgroundImage,
              'card-img-overlay':
                _vm.customOptions.cardBackgroundImage &&
                !_vm.customOptions.cardBackgroundImageAlign,
            }},[_c('div',{staticClass:"mt-5 pt-5 mb-5"},[(!_vm.customOptions.safetyCardText)?_c('h1',[_vm._v("X-Card")]):_vm._e(),(_vm.customOptions.safetyCardText)?_c('div',{staticClass:"safety-card-tet",domProps:{"innerHTML":_vm._s(_vm.customOptions.safetyCardText)}}):_vm._e()]),_c('button',{staticClass:"btn btn-outline-dark mt-5",on:{"click":function($event){return _vm.xCard()}}},[_vm._v(" Continue ")]),(!_vm.customOptions.safetyCardText)?_c('div',{},[_c('a',{staticClass:"x-card-text",attrs:{"href":"http://tinyurl.com/x-card-rpg"}},[_vm._v("About the X-Card")])]):_vm._e()]):_vm._e(),(
              _vm.customOptions.cardBackgroundImageAlign == 'bottom' &&
                _vm.roomInfo.currentCardIndex != 0
            )?_c('b-card-img',{attrs:{"src":_vm.customOptions.cardBackgroundImage,"alt":"Card Background image","bottom":""}}):_vm._e()],1)])],1):_vm._e(),_c('div',{staticClass:"btn-container"},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm"},[_c('b-button-group',{staticClass:"d-flex w-100",attrs:{"aria-role":"Deck control"}},[_c('b-button',{attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive,"color":"rgb(187, 138, 200)"},on:{"click":function($event){return _vm.shuffleAndResetGame()}}},[_vm._v("Re-shuffle")]),_c('b-button',{attrs:{"variant":"outline-dark"},domProps:{"innerHTML":_vm._s(
                _vm.customOptions.safetyCardButton
                  ? _vm.customOptions.safetyCardButton
                  : 'X-Card'
              )},on:{"click":function($event){return _vm.xCard()}}},[_vm._v("X-Card")]),(this.customOptions.showNextDeckButton)?_c('b-button',{attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
                  _vm.roomInfo.currentCardIndex >= _vm.roomInfo.locationOfLastCard},domProps:{"innerHTML":_vm._s(
                _vm.customOptions.showNextDeckButton
                  ? _vm.customOptions.showNextDeckButton
                  : 'Next Deck'
              )},on:{"click":function($event){return _vm.nextDeck()}}},[_vm._v(" Next Deck ")]):_vm._e(),(!this.customOptions.showNextDeckButton)?_c('b-dropdown',{attrs:{"variant":"outline-dark","id":"dropdown-1","text":_vm.customOptions.lastCardLabel,"disabled":_vm.roomInfo.xCardIsActive ||
                  _vm.roomInfo.currentCardIndex == _vm.gSheet.length - 1 ||
                  _vm.roomInfo.currentCardIndex == _vm.roomInfo.locationOfLastCard,"right":""}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.lastCard()}}},[_vm._v("Go to "+_vm._s(_vm.customOptions.lastCardLabel))]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.shuffleLastCard('center')}}},[_vm._v("Shuffle near middle")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.shuffleLastCard('end')}}},[_vm._v("Shuffle near end")])],1):_vm._e()],1)],1)])]),_c('div',{staticClass:"row"},[(_vm.customOptions.modalOneLabel || _vm.customOptions.modalTwoLabel)?_c('div',{staticClass:"btn-group col-sm",attrs:{"role":"group","aria-label":"Extra Info"}},[(_vm.customOptions.modalOneLabel)?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalOne",modifiers:{"modalOne":true}}],attrs:{"variant":"outline-dark"}},[_vm._v(_vm._s(_vm.customOptions.modalOneLabel))]):_vm._e(),_c('b-modal',{attrs:{"id":"modalOne","title":_vm.customOptions.modalOneLabel,"hide-footer":""}},[_c('div',{staticClass:"d-block text-left",domProps:{"innerHTML":_vm._s(_vm.customOptions.modalOneText)}})]),(_vm.customOptions.modalTwoLabel)?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalTwo",modifiers:{"modalTwo":true}}],attrs:{"variant":"outline-dark"}},[_vm._v(_vm._s(_vm.customOptions.modalTwoLabel))]):_vm._e(),_c('b-modal',{attrs:{"id":"modalTwo","title":_vm.customOptions.modalTwoLabel,"hide-footer":""}},[_c('div',{staticClass:"d-block text-left",domProps:{"innerHTML":_vm._s(_vm.customOptions.modalTwoText)}})])],1):_vm._e()])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }