<template>
  <div class="header-section">
    <b-navbar
      toggleable="md"
      type="dark"
      variant="dark"
      fixed="top"
      v-if="!$route.params.roomID"
    >
      <b-navbar-brand href="/">Arriba Town</b-navbar-brand>
      <b-navbar-toggle
        target="navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
      </b-navbar-toggle>

      <!-- <b-collapse id="navbarSupportedContent" is-nav>
        <b-navbar-nav>
          <b-nav-item href="https://www.aimbigemployment.com.au/"
            >Aimbig</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse> -->
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "app-header",
};
</script>
