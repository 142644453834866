var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.roomInfo)?_c('div',{staticClass:"shuffled game-room container"},[_c('div',{staticClass:"full-page-background"}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.customOptions.style)}}),(_vm.firebaseCacheError)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v("Warning: the length of the deck has changed since this room was first created. Click Restart to resync card data.")]):_vm._e(),(_vm.roomInfo)?_c('div',{},[(
        !_vm.customOptions.hideTitleInSession &&
        (_vm.customOptions.gameTitle || _vm.customOptions.byline)
      )?_c('div',{staticClass:"mb-4 game-meta d-none d-sm-block"},[(_vm.customOptions.gameTitle)?_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-sm"},[_c('h1',[_vm._v(_vm._s(_vm.customOptions.gameTitle))])])]):_vm._e(),(_vm.customOptions.byline)?_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-sm"},[_c('h4',[_vm._v(_vm._s(_vm.customOptions.byline))])])]):_vm._e()]):_vm._e(),(
        _vm.dataReady &&
        _vm.firebaseReady &&
        _vm.roomInfo &&
        Object.keys(_vm.roomInfo.extensionData).length > 1
      )?_c('div',[_c('app-extensionManager',{staticClass:"extension-upper",attrs:{"extensionData":_vm.roomInfo.extensionData,"extensionList":_vm.tempExtensionData,"roomInfo":_vm.roomInfo,"extensionLocation":'upper'},on:{"sync-extension":function($event){return _vm.syncExtension()}}})],1):_vm._e(),(
        !_vm.customOptions.hideNavigationButtons ||
        parseInt(_vm.customOptions.hideNavigationButtons) >
          _vm.roomInfo.currentCardIndex
      )?_c('div',{staticClass:"row mb-4"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"btn-group col-sm",attrs:{"role":"group","aria-label":"Card Controls"}},[_c('button',{staticClass:"btn btn-outline-dark",attrs:{"disabled":_vm.roomInfo.xCardIsActive ||
              _vm.roomInfo.currentCardIndex == 0 ||
              _vm.roomInfo.popCardOneIsActive ||
              _vm.roomInfo.popCardTwoIsActive ||
              _vm.roomInfo.popCardThreeIsActive},on:{"click":function($event){return _vm.previousCard()}}},[_vm._v(" Previous Card ")]),_c('b-button',{attrs:{"variant":"outline-dark"},domProps:{"innerHTML":_vm._s(
              _vm.customOptions.safetyCardButton
                ? _vm.customOptions.safetyCardButton
                : 'X-Card'
            )},on:{"click":function($event){return _vm.xCard()}}},[_vm._v("X-Card")]),_c('button',{staticClass:"btn btn-outline-primary",attrs:{"disabled":_vm.roomInfo.xCardIsActive ||
              _vm.roomInfo.currentCardIndex >= _vm.roomInfo.locationOfLastCard ||
              _vm.roomInfo.popCardOneIsActive ||
              _vm.roomInfo.popCardTwoIsActive ||
              _vm.roomInfo.popCardThreeIsActive},on:{"click":function($event){return _vm.nextCard()}}},[_vm._v(" Next Card ")])],1)])],1):_vm._e(),(
        _vm.customOptions.instructionsProgressBar &&
        _vm.roomInfo.currentCardIndex < _vm.firstNonInstruction &&
        _vm.roomInfo.currentCardIndex != 0
      )?_c('div',{staticClass:"row mb-4 game-meta"},[_c('div',{staticClass:"col-sm"},[_c('h2',[_vm._v("Instructions")]),_c('b-progress',{attrs:{"value":_vm.roomInfo.currentCardIndex,"max":_vm.firstNonInstruction - 1,"variant":"dark"}})],1)]):_vm._e(),(
        _vm.customOptions.displayCardCount &&
        _vm.customOptions.displayCardCount - 1 <= _vm.roomInfo.currentCardIndex &&
        _vm.roomInfo.currentCardIndex < _vm.roomInfo.locationOfLastCard
      )?_c('div',{staticClass:"row mb-3 game-meta card-counter"},[_c('div',{staticClass:"col-sm"},[_c('h2',[(_vm.customOptions.displayCardCountLabel)?_c('span',[_vm._v(_vm._s(_vm.customOptions.displayCardCountLabel))]):_c('span',[_vm._v("Cards seen:")]),_vm._v(" "+_vm._s(_vm.roomInfo.currentCardIndex - _vm.customOptions.displayCardCount + 1)+" ")])])]):_vm._e(),(_vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]])?_c('div',{staticClass:"mb-4"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"card main-card d-flex shadow img-fluid",class:{
            'bg-transparent':
              _vm.customOptions.coverImage && _vm.roomInfo.currentCardIndex == 0,
          }},[(_vm.customOptions.coverImage && _vm.roomInfo.currentCardIndex == 0)?_c('img',{staticClass:"card-img-top",staticStyle:{"width":"100%"},attrs:{"src":_vm.customOptions.coverImage}}):_vm._e(),(
              _vm.customOptions.cardBackgroundImage &&
              (!_vm.customOptions.coverImage || _vm.roomInfo.currentCardIndex != 0) &&
              !_vm.customOptions.cardBackgroundImageAlign
            )?_c('img',{staticClass:"card-img-top card-background",staticStyle:{"width":"100%"},attrs:{"src":_vm.customOptions.cardBackgroundImage}}):_vm._e(),(
              _vm.customOptions.cardBackgroundImageAlign == 'top' &&
              _vm.roomInfo.currentCardIndex != 0
            )?_c('b-card-img',{attrs:{"src":_vm.customOptions.cardBackgroundImage,"alt":"Card Background image","top":""}}):_vm._e(),((!_vm.dataReady || !_vm.firebaseReady) && !_vm.error)?_c('div',{staticClass:"card-body text-center"},[_c('h1',{staticClass:"m-5"},[_vm._v("Loading")]),_c('b-spinner',{staticClass:"m-5",staticStyle:{"width":"4rem","height":"4rem"},attrs:{"label":"Busy"}}),_c('p',[_vm._v(" If loading lasts more than a few seconds, please reload the page. ")]),(_vm.customOptions.debugLoading == 'TRUE')?_c('div',[_c('div',[_vm._v("Google Sheet ready: "+_vm._s(_vm.dataReady))]),_c('div',[_vm._v("Firebase ready: "+_vm._s(_vm.firebaseReady))]),_c('div',[_vm._v("Error: "+_vm._s(_vm.error))])]):_vm._e()],1):_vm._e(),(!_vm.customOptions.coverImage || _vm.roomInfo.currentCardIndex != 0)?_c('div',[(
                !_vm.roomInfo.xCardIsActive &&
                !_vm.roomInfo.popCardOneIsActive &&
                !_vm.roomInfo.popCardTwoIsActive &&
                !_vm.roomInfo.popCardThreeIsActive
              )?_c('div',{staticClass:"\n                card-body\n                justify-content-center\n                d-flex\n                align-items-center\n                mt-4\n              ",class:{
                'card-body': !_vm.customOptions.cardBackgroundImage,
                'card-img-overlay':
                  _vm.customOptions.cardBackgroundImage &&
                  !_vm.customOptions.cardBackgroundImageAlign,
              },staticStyle:{"white-space":"pre-line"}},[(!_vm.roomInfo.showCardBack)?_c('div',[(!_vm.customOptions.hideHeadersOnCards)?_c('h1',[_vm._v(" "+_vm._s(_vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]] .headerText)+" ")]):_vm._e(),_c('p',{staticClass:"mt-4 mb-4",domProps:{"innerHTML":_vm._s(
                    _vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]]
                      .bodyText
                  )}}),(
                    _vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]]
                      .cardBack && _vm.customOptions.reversableCards
                  )?_c('button',{staticClass:"btn btn-outline-dark",on:{"click":function($event){return _vm.flipCard()}}},[_c('svg',{staticClass:"bi bi-arrow-repeat",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"}})])]):_vm._e()]):_c('div',[_c('div',{staticClass:"mt-4 mb-4",domProps:{"innerHTML":_vm._s(
                    _vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]]
                      .cardBack
                  )}}),(
                    _vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]]
                      .cardBack && _vm.customOptions.reversableCards
                  )?_c('button',{staticClass:"btn btn-outline-dark",on:{"click":function($event){return _vm.flipCard()}}},[_c('svg',{staticClass:"bi bi-arrow-repeat",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"}})])]):_vm._e()])]):_vm._e()]):_vm._e(),(
              _vm.customOptions.lastCardReminderText &&
              _vm.customOptions.lastCardReminderFrequency &&
              _vm.roomInfo.currentCardIndex > _vm.firstNonInstruction &&
              (_vm.roomInfo.currentCardIndex - _vm.firstNonInstruction) %
                _vm.customOptions.lastCardReminderFrequency ==
                _vm.customOptions.lastCardReminderFrequency - 1
            )?_c('b-alert',{staticClass:"mx-3",attrs:{"show":"","variant":"info"},domProps:{"innerHTML":_vm._s(_vm.customOptions.lastCardReminderText)}}):_vm._e(),(_vm.roomInfo.xCardIsActive)?_c('div',{staticClass:"card-body align-items-center justify-content-center",class:{
              'card-body': !_vm.customOptions.cardBackgroundImage,
              'card-img-overlay':
                _vm.customOptions.cardBackgroundImage &&
                !_vm.customOptions.cardBackgroundImageAlign,
            }},[_c('div',{staticClass:"mt-5 pt-5 mb-5"},[(!_vm.customOptions.safetyCardText)?_c('h1',[_vm._v("X-Card")]):_vm._e(),(_vm.customOptions.safetyCardText)?_c('div',{staticClass:"safety-card-text",domProps:{"innerHTML":_vm._s(_vm.customOptions.safetyCardText)}}):_vm._e()]),_c('button',{staticClass:"btn btn-outline-dark mt-5",on:{"click":function($event){return _vm.xCard()}}},[_vm._v(" Continue ")]),(!_vm.customOptions.safetyCardText)?_c('div',{},[_c('a',{staticClass:"x-card-text",attrs:{"href":"http://tinyurl.com/x-card-rpg"}},[_vm._v("About the X-Card")])]):_vm._e()]):_vm._e(),(_vm.roomInfo.popCardOneIsActive && !_vm.roomInfo.xCardIsActive)?_c('div',{staticClass:"card-body align-items-center justify-content-center",class:{
              'card-body': !_vm.customOptions.cardBackgroundImage,
              'card-img-overlay':
                _vm.customOptions.cardBackgroundImage &&
                !_vm.customOptions.cardBackgroundImageAlign,
            }},[_c('div',{staticClass:"mt-5 pt-5 mb-5"},[(!_vm.customOptions.popCardOneText)?_c('h1',[_vm._v("Pop Card One")]):_vm._e(),(_vm.customOptions.popCardOneText)?_c('div',{staticClass:"safety-card-text",domProps:{"innerHTML":_vm._s(_vm.customOptions.popCardOneText)}}):_vm._e()]),_c('button',{staticClass:"btn btn-outline-dark mt-5",on:{"click":function($event){return _vm.popCardOne()}}},[_vm._v(" Done ")])]):_vm._e(),(_vm.roomInfo.popCardTwoIsActive && !_vm.roomInfo.xCardIsActive)?_c('div',{staticClass:"card-body align-items-center justify-content-center",class:{
              'card-body': !_vm.customOptions.cardBackgroundImage,
              'card-img-overlay':
                _vm.customOptions.cardBackgroundImage &&
                !_vm.customOptions.cardBackgroundImageAlign,
            }},[_c('div',{staticClass:"mt-5 pt-5 mb-5"},[(!_vm.customOptions.popCardTwoText)?_c('h1',[_vm._v("Pop Card One")]):_vm._e(),(_vm.customOptions.popCardTwoText)?_c('div',{staticClass:"safety-card-text",domProps:{"innerHTML":_vm._s(_vm.customOptions.popCardTwoText)}}):_vm._e()]),_c('button',{staticClass:"btn btn-outline-dark mt-5",on:{"click":function($event){return _vm.popCardTwo()}}},[_vm._v(" Done ")])]):_vm._e(),(_vm.roomInfo.popCardThreeIsActive && !_vm.roomInfo.xCardIsActive)?_c('div',{staticClass:"card-body align-items-center justify-content-center",class:{
              'card-body': !_vm.customOptions.cardBackgroundImage,
              'card-img-overlay':
                _vm.customOptions.cardBackgroundImage &&
                !_vm.customOptions.cardBackgroundImageAlign,
            }},[_c('div',{staticClass:"mt-5 pt-5 mb-5"},[(!_vm.customOptions.popCardThreeText)?_c('h1',[_vm._v("Pop Card One")]):_vm._e(),(_vm.customOptions.popCardThreeText)?_c('div',{staticClass:"safety-card-text",domProps:{"innerHTML":_vm._s(_vm.customOptions.popCardThreeText)}}):_vm._e()]),_c('button',{staticClass:"btn btn-outline-dark mt-5",on:{"click":function($event){return _vm.popCardThree()}}},[_vm._v(" Done ")])]):_vm._e(),(
              _vm.customOptions.cardBackgroundImageAlign == 'bottom' &&
              _vm.roomInfo.currentCardIndex != 0
            )?_c('b-card-img',{attrs:{"src":_vm.customOptions.cardBackgroundImage,"alt":"Card Background image","bottom":""}}):_vm._e()],1)])],1):_vm._e(),(
        _vm.dataReady &&
        _vm.firebaseReady &&
        _vm.roomInfo &&
        Object.keys(_vm.roomInfo.extensionData).length > 1
      )?_c('div',[_c('app-extensionManager',{attrs:{"extensionData":_vm.roomInfo.extensionData,"extensionList":_vm.tempExtensionData,"roomInfo":_vm.roomInfo},on:{"sync-extension":function($event){return _vm.syncExtension()}}})],1):_vm._e(),_c('div',{staticClass:"row"},[(_vm.customOptions.modalOneLabel || _vm.customOptions.modalTwoLabel)?_c('div',{staticClass:"btn-group col-sm",attrs:{"role":"group","aria-label":"Extra Info"}},[(
            _vm.customOptions.modalOneLabel &&
            _vm.roomInfo.currentCardIndex >= _vm.customOptions.modalOneFirstVisible
          )?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalOne",modifiers:{"modalOne":true}}],attrs:{"variant":"outline-dark"}},[_vm._v(_vm._s(_vm.customOptions.modalOneLabel))]):_vm._e(),_c('b-modal',{attrs:{"id":"modalOne","title":_vm.customOptions.modalOneLabel,"hide-footer":""}},[_c('div',{staticClass:"d-block text-left",domProps:{"innerHTML":_vm._s(_vm.customOptions.modalOneText)}})]),(
            _vm.customOptions.modalTwoLabel &&
            _vm.roomInfo.currentCardIndex >= _vm.customOptions.modalTwoFirstVisible
          )?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalTwo",modifiers:{"modalTwo":true}}],attrs:{"variant":"outline-dark"}},[_vm._v(_vm._s(_vm.customOptions.modalTwoLabel))]):_vm._e(),_c('b-modal',{attrs:{"id":"modalTwo","title":_vm.customOptions.modalTwoLabel,"hide-footer":""}},[_c('div',{staticClass:"d-block text-left",domProps:{"innerHTML":_vm._s(_vm.customOptions.modalTwoText)}})])],1):_vm._e()]),_c('p'),(_vm.userRole == 'facilitator')?_c('hr'):_vm._e(),(
        (!_vm.customOptions.facilitatorMode || _vm.userRole == 'facilitator') &&
        (_vm.customOptions.popCardOneLabel ||
          _vm.customOptions.popCardTwoLabel ||
          _vm.customOptions.popCardThreeLabel)
      )?_c('div',{staticClass:"row mb-4"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"btn-group col-sm",attrs:{"role":"group","aria-label":"Card Controls"}},[(_vm.customOptions.popCardOneLabel)?_c('b-button',{attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
              _vm.roomInfo.popCardTwoIsActive ||
              _vm.roomInfo.popCardThreeIsActive},domProps:{"innerHTML":_vm._s(
              _vm.customOptions.popCardOneLabel
                ? _vm.customOptions.popCardOneLabel
                : 'Popcard One'
            )},on:{"click":function($event){return _vm.popCardOne()}}},[_vm._v("Popcard One")]):_vm._e(),(_vm.customOptions.popCardTwoLabel)?_c('b-button',{attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
              _vm.roomInfo.popCardOneIsActive ||
              _vm.roomInfo.popCardThreeIsActive},domProps:{"innerHTML":_vm._s(
              _vm.customOptions.popCardTwoLabel
                ? _vm.customOptions.popCardTwoLabel
                : 'Popcard Two'
            )},on:{"click":function($event){return _vm.popCardTwo()}}},[_vm._v("Popcard Two")]):_vm._e(),(_vm.customOptions.popCardThreeLabel)?_c('b-button',{attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
              _vm.roomInfo.popCardOneIsActive ||
              _vm.roomInfo.popCardTwoIsActive},domProps:{"innerHTML":_vm._s(
              _vm.customOptions.popCardThreeLabel
                ? _vm.customOptions.popCardThreeLabel
                : 'Popcard Three'
            )},on:{"click":function($event){return _vm.popCardThree()}}},[_vm._v("Popcard Three")]):_vm._e()],1)])],1):_vm._e(),_c('p'),(!_vm.customOptions.facilitatorMode || _vm.userRole == 'facilitator')?_c('div',{staticClass:"btn-container"},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm"},[_c('b-button-group',{staticClass:"d-flex w-100",attrs:{"aria-role":"Deck control"}},[(
                (!_vm.customOptions.facilitatorMode ||
                  _vm.userRole == 'facilitator') &&
                _vm.customOptions.chapterOneFirstCard
              )?_c('b-button',{attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
                _vm.roomInfo.popCardOneIsActive ||
                _vm.roomInfo.popCardTwoIsActive ||
                _vm.roomInfo.popCardThreeIsActive,"color":"rgb(187, 138, 200)"},domProps:{"innerHTML":_vm._s(
                _vm.customOptions.chapterOneLabel
                  ? _vm.customOptions.chapterOneLabel
                  : 'Chapter One'
              )},on:{"click":function($event){return _vm.goToChapter(_vm.customOptions.chapterOneFirstCard)}}},[_vm._v("Chapter One")]):_vm._e(),(
                (!_vm.customOptions.facilitatorMode ||
                  _vm.userRole == 'facilitator') &&
                _vm.customOptions.chapterTwoFirstCard
              )?_c('b-button',{attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
                _vm.roomInfo.popCardOneIsActive ||
                _vm.roomInfo.popCardTwoIsActive ||
                _vm.roomInfo.popCardThreeIsActive,"color":"rgb(187, 138, 200)"},domProps:{"innerHTML":_vm._s(
                _vm.customOptions.chapterTwoLabel
                  ? _vm.customOptions.chapterTwoLabel
                  : 'Chapter Two'
              )},on:{"click":function($event){return _vm.goToChapter(_vm.customOptions.chapterTwoFirstCard)}}},[_vm._v("Chapter Two")]):_vm._e(),(
                (!_vm.customOptions.facilitatorMode ||
                  _vm.userRole == 'facilitator') &&
                _vm.customOptions.chapterThreeFirstCard
              )?_c('b-button',{attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
                _vm.roomInfo.popCardOneIsActive ||
                _vm.roomInfo.popCardTwoIsActive ||
                _vm.roomInfo.popCardThreeIsActive,"color":"rgb(187, 138, 200)"},domProps:{"innerHTML":_vm._s(
                _vm.customOptions.chapterThreeLabel
                  ? _vm.customOptions.chapterThreeLabel
                  : 'Chapter Three'
              )},on:{"click":function($event){return _vm.goToChapter(_vm.customOptions.chapterThreeFirstCard)}}},[_vm._v("Chapter Three")]):_vm._e(),(
                (!_vm.customOptions.facilitatorMode ||
                  _vm.userRole == 'facilitator') &&
                _vm.customOptions.chapterFourFirstCard
              )?_c('b-button',{attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
                _vm.roomInfo.popCardOneIsActive ||
                _vm.roomInfo.popCardTwoIsActive ||
                _vm.roomInfo.popCardThreeIsActive,"color":"rgb(187, 138, 200)"},domProps:{"innerHTML":_vm._s(
                _vm.customOptions.chapterFourLabel
                  ? _vm.customOptions.chapterFourLabel
                  : 'Chapter Four'
              )},on:{"click":function($event){return _vm.goToChapter(_vm.customOptions.chapterFourFirstCard)}}},[_vm._v("Chapter Four")]):_vm._e(),(
                (!_vm.customOptions.facilitatorMode ||
                  _vm.userRole == 'facilitator') &&
                _vm.customOptions.chapterFiveFirstCard
              )?_c('b-button',{attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
                _vm.roomInfo.popCardOneIsActive ||
                _vm.roomInfo.popCardTwoIsActive ||
                _vm.roomInfo.popCardThreeIsActive,"color":"rgb(187, 138, 200)"},domProps:{"innerHTML":_vm._s(
                _vm.customOptions.chapterFiveLabel
                  ? _vm.customOptions.chapterFiveLabel
                  : 'Chapter Five'
              )},on:{"click":function($event){return _vm.goToChapter(_vm.customOptions.chapterFiveFirstCard)}}},[_vm._v("Chapter Five")]):_vm._e(),(
                (!_vm.customOptions.facilitatorMode ||
                  _vm.userRole == 'facilitator') &&
                _vm.customOptions.chapterSixFirstCard
              )?_c('b-button',{attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
                _vm.roomInfo.popCardOneIsActive ||
                _vm.roomInfo.popCardTwoIsActive ||
                _vm.roomInfo.popCardThreeIsActive,"color":"rgb(187, 138, 200)"},domProps:{"innerHTML":_vm._s(
                _vm.customOptions.chapterSixLabel
                  ? _vm.customOptions.chapterSixLabel
                  : 'Chapter Six'
              )},on:{"click":function($event){return _vm.goToChapter(_vm.customOptions.chapterSixFirstCard)}}},[_vm._v("Chapter Six")]):_vm._e()],1)],1)])]):_vm._e(),(!_vm.customOptions.facilitatorMode || _vm.userRole == 'facilitator')?_c('div',{staticClass:"btn-container"},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm"},[_c('b-button-group',{staticClass:"d-flex w-100",attrs:{"aria-role":"Deck control"}},[(
                !_vm.customOptions.facilitatorMode || _vm.userRole == 'facilitator'
              )?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.reshuffleConfirm",modifiers:{"reshuffleConfirm":true}}],attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive,"color":"rgb(187, 138, 200)"}},[_vm._v("Restart and reshuffle")]):_vm._e()],1)],1)])]):_vm._e(),_c('b-modal',{attrs:{"id":"modalNextDeckConfirm","title":"Advance?","hide-footer":""}},[_c('p'),_c('div',{staticClass:"text-center mb-3"},[_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){return _vm.nextDeck()}}},[_vm._v("Advance to "+_vm._s(_vm.customOptions.showNextDeckButton ? _vm.customOptions.showNextDeckButton : "Next Deck"))])],1)]),_c('b-modal',{attrs:{"id":"reshuffleConfirm","title":"Restart and Reshuffle","hide-footer":""}},[_c('p',[_vm._v("Do you want to reshuffle all of the prompts and restart the game?")]),_c('div',{staticClass:"text-center mb-3"},[_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){return _vm.shuffleAndResetGame()}}},[_vm._v("Restart and Reshuffle")])],1)])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }