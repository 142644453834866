<template>
  <div class="homepage">
    <div class="container">
      <!-- <div
        v-if="!$route.params.gameType"
        class="card shadow mb-4"
        style="margin-top: 76px"
      >
        <div class="card-body">
          <div id="homepage-intro-text" v-if="!$route.params.gSheetID"></div>
        </div>
      </div> -->

      <div class="card shadow mb-4" style="margin-top: 76px">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col-sm-12 text-center"></div>
            <div class="col-sm-12">
              <img
                class="img img-fluid"
                style="width: 80%; display: block; margin: 20px auto 0 auto"
                src="https://amble.studio/wp-content/uploads/2021/12/Arriba-Town-Rectangle-1.png"
                alt="Arriba Town"
              />

              <p class="mt-4" style="text-align: center">
                A tiny quest to find your path and change your village for the
                better.
              </p>
            </div>
            <br />
            <div
              class="text-center"
              style="
                background: lightgray;
                margin: 20px 20px 0 20px;
                padding: 20px;
                border: 1px solid red;
              "
            >
              <p>
                If you've found your way here looking for a shared game please
                ask your facilitator to resend you the link.
              </p>
              <p>
                Facilitators - create a session below and share the in-session
                link with participants.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-gameLauncherSingleGame
      v-if="launcherOnHomepage"
      :gameRoute="String(launcherOnHomepage)"
    ></app-gameLauncherSingleGame>
    <app-gameMaker
      v-if="!launcherOnHomepage"
      :routeRoomID="$route.params.roomID"
      :routeGSheetID="$route.params.gSheetID"
      :routeGameType="$route.params.gameType"
    ></app-gameMaker>
  </div>
</template>

<script>
import GameMaker from "./GameMaker.vue";
import GameLauncherSingleGame from "./GameLauncherSingleGame.vue";

export default {
  name: "app-homepage",
  components: {
    "app-gameMaker": GameMaker,
    "app-gameLauncherSingleGame": GameLauncherSingleGame,
  },
  props: {
    routeGameType: String,
    routeGSheetID: String,
    routeRoomID: String,
  },
  data() {
    return {
      launcherOnHomepage: "/Games/Arriba-Town/", // if you want the game launcher on the homepage, add in the route up until the roomID e.g. "/Games/Around-The-Realm/"
    };
  },
  mounted() {
    let body = document.getElementById("app"); // document.body;
    body.classList.remove(body.classList[0]);

    let page = document.getElementsByClassName("non-footer-content")[0]; // document.body;
    page.classList.remove(page.classList[1]);

    document.dispatchEvent(new Event("x-app-rendered"));
  },
};
</script>

<style scoped>
.homepage {
  max-width: 600px;
  margin: auto;
}

li {
  list-style-type: disc;
  display: list-item;
  margin-left: 20px;
}
</style>